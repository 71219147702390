import {Box, Button, Container, Typography} from '@mui/material'
import * as React from 'react'
import {Link} from "gatsby";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

export default function Index() {
  return (
    <>
      <Box
        component="main"
        sx={{
          alignItems: 'center',
          justifyContent: 'center',
          display: 'flex',
          flexGrow: 1,
          height: '100vh',
          maxWidth: '800px',
          margin: '0 auto',
        }}
      >
        <Container maxWidth="md">
          <Box
            sx={{
              alignItems: 'center',
              display: 'flex',
              flexDirection: 'column'
            }}
          >
            <Typography
              align="center"
              color="textPrimary"
              variant="h1"
            >
              404: La page que vous recherchez est introuvable.
            </Typography>
            <Box sx={{ textAlign: 'center' }}>
              <img
                alt="Under development"
                src="/static/images/undraw_page_not_found_su7k.svg"
                style={{
                  marginTop: 50,
                  display: 'inline-block',
                  maxWidth: '100%',
                  width: 560
                }}
              />
            </Box>
            <Link
              to="/"
              style={{
                textDecoration: 'none'
              }}
            >
              <Button
                component="a"
                startIcon={(<ArrowBackIcon fontSize="small" />)}
                sx={{ mt: 3 }}
                variant="contained"
              >
                Tableau de bord
              </Button>
            </Link>
          </Box>
        </Container>
      </Box>
    </>
  )
}
